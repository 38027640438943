import React from "react";

export const Contact = () => {
    return (
        <div>
            <h1>Contact DGS</h1>
            <p>Email : <a href="mailto:info@dadgumsalsa.com">info@dadgumsalsa.com</a></p>
            <p>Twitter : <a href="http://twitter.com/dadgumsalsa">@dadgumsalsa</a></p>
            <p>Facebook : <a href="http://www.facebook.com/dadgumsalsa">Dad Gum Salsa</a></p>
        </div>
    );
};

export const LargeTomato = () => {
    return (
        <img className="blog-image" src="/images/tomato.png" alt="tomato"/>
    );
};

export const LargeJalapeno = () => {
    return (
        <img className="right-image" src="/images/DGS_jap.png" alt="jalapeno"/>
    );
};

export const AboutVideo = () => {
    return (
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SqXOOxCUh3U"
            title="Dad Gum Salsa recipe video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );
};

export const Order = () => {
    return (
        <img src="/images/noorders.png" alt="order form"/>
    )
};

// @ts-ignore
export const BottomNav = ({ handleClick }) => {

    return (
        <div className="menu-frame">
            <table className="menu-bar">
                <tbody>
                <tr>
                    <td>
                        <a href="/" onClick={(e) => handleClick('/', e)}>
                            <img src="/menu_items/DGSfrontTitle.png" alt="Dad Gum Salsa"/>
                        </a>
                    </td>
                    <td>
                        <a className="linkopacity" href="/" onClick={(e) => handleClick('/about/', e)}>
                            <img src="/menu_items/DGSaboutLink.png" alt="About" />
                        </a>
                    </td>
                    <td>
                        <a className="linkopacity" href="/" onClick={(e) => handleClick('/order/', e)}>
                            <img src="/menu_items/DGSorderLink.png" alt="Order"/>
                        </a>
                    </td>
                    <td>
                        <a className="linkopacity" href="/" onClick={(e) => handleClick('/contact/', e)}>
                            <img src="/menu_items/DGScontactLink.png" alt="Contact"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};