import React, {MouseEvent, useState} from 'react';
import './App.css';
import {
    AboutVideo,
    BottomNav,
    Contact,
    LargeJalapeno,
    LargeTomato,
    Order
} from './components';

const App = () => {
    const [currentPath, setCurrentPath] = useState('/');

    const handleClick = (path: string, event: React.MouseEvent<MouseEvent>) => {
        event.preventDefault(); // Prevent default behavior of anchor tag
        setCurrentPath(path);
    };

    return (
        <div className="App">
            <table>
                <tbody>
                <tr>
                    <td className="left-frame">
                        {currentPath === "/contact/" && <LargeJalapeno/>}
                        {["/about/", "/order/"].includes(currentPath) && <LargeTomato/>}
                    </td>
                    <td className="main-frame">
                        {currentPath === "/" && <LargeJalapeno/>}
                        {currentPath === "/contact/" && <Contact/>}
                        {currentPath === "/about/" && <AboutVideo/>}
                        {currentPath === "/order/" && <Order/>}
                    </td>
                </tr>
                </tbody>
            </table>
            <BottomNav handleClick={handleClick}/>
        </div>
    );
};

export default App;
